(function($) {
  'use strict';
  var alerts = [];

  $.fn.cieAlert = function() {
    // get current visible modal or create new one
    var $lastModal = $('.modal-alerts').not('.hidden').last();
    var $modal;

    var nextAlert = function() {
      if (alerts.length === 0) {
        return;
      }

      alerts.shift().removeClass('hidden').addClass('current-modal').modal();
    };

    if ($lastModal.length === 0) {
      $modal = $('#alerts-modal').clone()
          .attr('id', '').appendTo('body').removeClass('hidden')
          .addClass('current-modal').modal()
          .on('hidden.bs.modal', function() {
            $modal.remove();
            nextAlert();
          });

    } else {
      $modal = $('#alerts-modal').clone()
          .attr('id', '').appendTo('body')
          .on('hidden.bs.modal', function() {
            $modal.remove();
            nextAlert();
          });
      alerts.push($modal);
    }
    return $modal.find('.modal-body').append(this);
  };

  $(function() {
    $('.alerts .alert, .enrollee-form .alert').each(function() {
      $(this).cieAlert();
    });
  });
}(jQuery));
