(function($) {
  $(function() {

    if (!window.google) return;

    var geocoder = new google.maps.Geocoder();

    function getFullAddress($mapCanvas) {
      var fullAddress = $mapCanvas.attr('data-address') || '';

      if ($mapCanvas.attr('data-address2')) {
        fullAddress += ' ' + $mapCanvas.attr('data-address2');
      }

      fullAddress += ',' + $mapCanvas.attr('data-city');
      fullAddress += ',' + $mapCanvas.attr('data-state');

      if ($mapCanvas.attr('data-zip-code')) {
        fullAddress += ' ' + $mapCanvas.attr('data-zip-code');
      }

      fullAddress += ',' + $mapCanvas.attr('data-country');
      return fullAddress;
    }

    function setMapSizes() {
      $('.google-map-dynamic.has-map').each(function() {
        var $mapCanvas = $(this);
        $mapCanvas.height(Math.floor($mapCanvas.width()));
      });

    }

    $('.google-map-static').each(function() {
      var $mapCanvas = $(this);
      var fullAddress = getFullAddress($mapCanvas);

      $mapCanvas.addClass('has-map');
      var w = Math.round($mapCanvas.width());
      var url = 'http://maps.googleapis.com/maps/api/staticmap';
      url += '?size='+w+'x'+w;
      url += '&maptype=roadmap&markers=';
      url += 'size:mid%7C';
      url += 'color:red%7C';
      url += encodeURIComponent(fullAddress);
      url += '&zoom=9';
      url += '&sensor=false';

      var $link = $('<a></a>')
          .attr('href', 'http://maps.google.com/?q=' +
              encodeURIComponent(fullAddress))
          .appendTo($mapCanvas);

      $('<img>').attr('src', url).appendTo($link);
    });

    function loadDynamics() {
      $('.google-map-dynamic:visible').not('.has-map').each(function() {
        try {
          var $mapCanvas = $(this);
          $mapCanvas.addClass('has-map');
          var fullAddress = getFullAddress($mapCanvas);

          geocoder.geocode({address:fullAddress}, function(geoCodeResult) {
            setMapSizes();

            try {

              var map = new google.maps.Map($mapCanvas[0], {
                center: geoCodeResult[0].geometry.location,
                zoom: 9,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: true,
                mapTypeControlOptions: {
                  style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
                },
                zoomControl: true,
                zoomControlOptions: {
                  style: google.maps.ZoomControlStyle.SMALL
                }
              });


              new google.maps.Marker({
                position: geoCodeResult[0].geometry.location,
                map: map,
                title:geoCodeResult[0].formatted_address
              });
            } catch (E) {}


          });
        } catch (E) {}
      });
    }
  
    $(window).on('resize', setMapSizes);
    $(window).on('resize', loadDynamics);
    loadDynamics();

  });



})(jQuery);