(function($) {
  'use strict';

  var allQuestions = [];
  window.faqAllQuestions = allQuestions;

  $('.faq.question').each(function() {
    var $qnode = $(this);
    allQuestions.push({
      id: ($qnode.attr('href') || $qnode.attr('data-target')).substring(1),
      text: ($qnode.text() + ' ' +
          $($(this).attr('data-target')).text()).replace(/\s+/, ' '),
    });
  });

  var formatter = function (state) {
    var qIndex = state.text.indexOf('?');
    return $('<span>' + state.text.substring(0, qIndex + 1) +
      '<span style="display:none">' +
      state.text.substring(qIndex + 1) + '</span></span>');
  };

  function matchCustom(params, data) {
    // If there are no search terms, return all of the data
    if ((params.term || '').trim() === '') {
      return data;
    }

    // Do not display the item if there is no 'text' property
    if (typeof data.text === 'undefined') {
      return null;
    }

    // `params.term` should be the term that is used for searching
    // `data.text` is the text that is displayed for the data object
    var terms = params.term.split(/\s+/);

    var matchCount = 0;
    var itemText = data.text.toLowerCase();
    terms.forEach(function (t) {
      if (itemText.indexOf(t.toLowerCase()) > -1) {
        matchCount += 1;
      }
    });

    if (matchCount === terms.length) {
      return data;
    }

    // Return `null` if the term should not be displayed
    return null;
  }

  function showFaqWithId(elemId) {
    var $el = $('#' + elemId);
    if (!$el.is('.collapse.in')) {
      $('.question[data-target="#' + elemId + '"]').trigger('click')
    }
  }

  // setup search
  try {
    $('input.faq-search').select2({
      data: allQuestions,
      matcher: matchCustom,
      templateResult: formatter,
      allowClear: true,
      templateSelection: formatter,
      // maximumSelectionSize: 1,
      width: '100%',
      theme: 'bootstrap',
    }).on('change', function () {
      var elemId = $(this).val();

      if (elemId) {

        var qText = $('.question[data-target="#' + elemId + '"]').text();

        var showThisOne = function () {
          setTimeout(function () {
            $('html, body').animate({
              scrollTop: $('#' + elemId).offset().top - 60
            }, 200);
          }, 50);

          showFaqWithId(elemId);
        };

        if (qText) {
          $('<a></a>').text(qText).prepend(
            $('<a class="close">&times;</a>').on('click', function () {
              $(this).parent().parent().remove();
            })).on('click', showThisOne).appendTo('.faq-search-history').wrap('<li></li>');

          while ($('.faq-search-history li').length > 5) {
            $('.faq-search-history li:last').remove();
          }
        }

        showThisOne();
      }
    });


  } catch (err) {
    $('input.faq-search').hide();
    console.log('ERROR', err);
  }

  $('.faq').show();
}(jQuery));
