
(function($) {
  $(function() {
    console.log('starting CIE carousel');
    $('.raw-carousel').each(function(i) {
      var $rc = $(this);
      var $images = $('.plugin_picture:has(img)', $rc);
      var $headers = $('h3, h4, h5, h6', $rc);
      var $captions = $('p', $rc).not('p:has(.plugin_link)');
      var id = 'constructed-carousel-' + i;

      if (($images.length === $captions.length) && ($headers.length === 0 || $headers.length === $captions.length)) {
          var noHeaders = $headers.length === 0;
          var $newRoot = $('<div id="' + id + '" class="carousel slide"></div>');
          $newRoot.append($('<ol class="carousel-indicators"></ol>'));
          $newRoot.append($('<div class="carousel-inner"></div>'));
          $newRoot.append($('<a class="carousel-control left" href="#'+id+'" data-slide="prev">&lsaquo;</a>'));
          $newRoot.append($('<a class="carousel-control right" href="#'+id+'" data-slide="next">&rsaquo;</a>'));

          $rc.append($newRoot);

          var count = $images.length;
          for (var j = 0; j < count; j++) {
              $('ol', $newRoot).append($('<li data-target="#'+id+'" data-slide-to="'+j+'"></li>'));
              var $item = $('<div class="item"></div>');
              $('div.carousel-inner', $newRoot).append($item);

              $item.append($images[j]);

              var $caption = $('<div class="carousel-caption"></div>');
              $item.append($caption);
              if (!noHeaders) $caption.append($headers[j]);
              $caption.append($captions[j]);

          }

          $('ol li:first, .carousel-inner .item:first', $newRoot).addClass('active');
          $('ol', $newRoot).remove();

          // $('div:has(span)', $rc).remove();
          $('h1, h2', $rc).appendTo($newRoot).clone().addClass('visible-below-480w').insertBefore($newRoot);

          var $link = $('p:has(.plugin_link a)', $rc);
          if ($link.length > 0) {
              $link.hide();
              var href = $('a', $link).attr('href');

              $('img', $newRoot).wrap('<a class="carousel-link" href="'+(href||'#')+'"></a>');
              $('h3, h4, p', $newRoot).each(function() {
                  var text = $(this).text();
                  var $a = $('<a></a>');
                  $a.attr('href', href).text(text).addClass('carousel-link');
                  $(this).text('').append($a);
              });
          }

          $newRoot.carousel({interval:5000});
      }


  });

  });
})(jQuery);
