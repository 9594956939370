(function($) {
  $(function() {

    $.fn.select2.defaults.set('theme', 'bootstrap');

    function formatSingleResult() { }

    $('form.select2-selectors[data-select2-fields]').each(function() {
      var $form = $(this);
      var fields = $form.attr('data-select2-fields').split(',');

      $.each(fields, function(i, field) {

        var $select = $('select[name*="'+field+'"]', $form);

        function formatSingleResult(result) {
          var optionText = result.text;
          var $search = $select.data("select2").selection.$search;
          if (!$search) {
            return $('<span>' + optionText + '</span>');
          }
          var term = $search.val();
          var idx = optionText.toLowerCase().indexOf(term.toLowerCase());
          var boldTermText;
          if (idx !== -1) {
            boldTermText = optionText.substr(0, idx) + '<b>' +
                optionText.substr(idx, term.length) + '</b>' +
                optionText.substr(idx + term.length, optionText.length - (idx + term.length));
          } else {
            boldTermText = optionText;
          }
          var $item = $('<span>' + boldTermText + '</span>');
          return $item;
        }

        var initArgs = {
            width:'100%',
            minimumResultsForSearch: 30,
            allowClear: false,
            templateResult: formatSingleResult};

        if ($select.attr('placeholder')) {
          console.log('setting placeholder to', $select.attr('placeholder'));
          initArgs.placeholder = $select.attr('placeholder');
          initArgs.allowClear = true;
        }

        if ($select.parents('.collapse').first()) {
          var $collapseDiv = $select.parents('.collapse').first();
          $collapseDiv.removeClass('collapse').addClass('collapse-with-dimensions');
        }

        $select.addClass('has-select2')
          .select2(initArgs);
      });
    });

    $('.collapse-with-dimensions').each(function() {
      $(this).removeClass('collapse-with-dimensions').addClass('collapse');
    });
  });
})(jQuery);
