(function($) {
  'use strict';

  var idCounter = 0;

  $(function() {
    $('.no-js').removeClass('no-js');
    $('.hide-on-click').on('click', function(ev) {
      $(ev.target).hide();
    });

    if ($('.form-group.has-error').length > 0) {
      $([document.documentElement, document.body]).animate({
        scrollTop: $('.form-group.has-error').parents('form').offset().top
      }, 200);
    }

    $('.carousel.slide.cie-auto').each(function() {
      var $el = $(this);
      if ($el.attr('id')) {
        return;
      }

      var id = 'site-js-id-counter-' + (idCounter++);
      $el[0].id = id;
      $('.carousel-indicators li', $el).attr('data-target', '#' + id);
      $('.carousel-control', $el).attr('href', '#' + id);

      $el.carousel();
    });

  });
}(jQuery));
