(function($) {
  $(function() {

    // if user submitted and something other than payment was wrong, then
    // hide the stripe fields
    $('form.stripe-payment').not('.coupon').each(function() {
      var $tokenInput = $('input[name$="stripe_card_token"]', this);
      if ($tokenInput.val()) {
        $('[data-stripe]', this).parents('.form-group').hide();
        $('.hidden.unless-have-stripe-token', this).removeClass('hidden');
      }
    });

    $('form.stripe-payment *[data-stripe]').attr('name', null);

    $('form.stripe-payment').not('.coupon').on('submit', function(ev) {

      var $form = $(this);
      var $tokenInput = $('input[name$="stripe_card_token"]', $(ev.target));
      var $keyInput = $('input[name$="stripe_publishable_key"]', $(ev.target));
      if ($tokenInput.val() || !$keyInput.val()) {
        return;
      }

      $('button, input[type="submit"]', $form).prop('disabled', true);

      Stripe.setPublishableKey($keyInput.val());

      ev.preventDefault();


      Stripe.createToken($form, function(status, response) {
        if (status === 200 && response && !response.error) {
          $tokenInput.val(response.id);
          $form.trigger('submit');
        } else if (response && response.error) {
          var errorText = response.error.message;
          try {
            console.log(response.error);
          } catch(e) {}
          if (response.error.code || response.error.type) {
            var codeType = ' (';
            if (response.error.type) {
              codeType += response.error.type + ', ';
            }
            if (response.error.code) {
              codeType += response.error.code;
            }
            errorText += codeType + ')';
          }

          try {
            throw new Error('Credit Card Error: ' + errorText);
          } catch(e) {
            try {
              _errs.push(e);
            } catch (e2) {}
          }

          $('<div class="alert alert-error"></div>').text(errorText).cieAlert();

          $('button, input[type="submit"]', $form).prop('disabled', false);
        } else {
          $('.payment-errors', $form).text('Payment failed, please try again');
          $('button, input[type="submit"]', $form).prop('disabled', false);
        }
      });

      return false;
    });
  });

})(jQuery);
