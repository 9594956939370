(function($) {

  var ADDRESS_FIELDS = [
      'address',
      'address2',
      'city',
      'state',
      'zip_code',
      'country'
  ];

  function clearInputs($target) {
    $('input', $target).val('');
    $('select', $target).not('.has-select2').val('');
    $('.has-select2', $target).not('[multiple]').select2('val', '');
    $('.has-select2[multiple]', $target).select2('val', []);
  }

  $(function() {
    var $billingForm = $('.enrollee-form').first();
    if ($billingForm.length === 0) return;


    var showHideSpeed = 0;

    var priceInfo = $billingForm.parents('form').attr('data-price-info');
    if (priceInfo) {
      priceInfo = $.parseJSON(priceInfo);
      $('select[name$="price"]', $billingForm.parents('form')).on('change', function(ev) {
        console.log('change of select triggered');
        var priceId = $(ev.target).val();
        var maxLanguages = (priceInfo[priceId]) ? priceInfo[priceId].maxLanguages : 0;
        var $languageControls = $billingForm.parents('form').find(
            '.form-group select[name$="languages"]').parents('.form-group');

        if (maxLanguages === 0) {
          $languageControls.hide(showHideSpeed);
          clearInputs($languageControls);
        } else {
          $languageControls.show(showHideSpeed);
        }
      }).trigger('change');

    }

    function copyAddressFromBilling() {

      $('.enrollee-form input' +
          '[name$="same_address_as_billing"]:checked')
          .parents('.enrollee-form').each(function() {

        var $targetForm = $(this);
        $.each(ADDRESS_FIELDS, function(i, field) {
          var $srcInput = $('select[name$="'+field+'"], input[name$="'+field+'"]', $billingForm).not('.has-select2');
          var $dstInput = $('select[name$="'+field+'"], input[name$="'+field+'"]', $targetForm).not('.has-select2');

          $dstInput.val($srcInput.val());

          var $srcSelect2 = $('select.has-select2[name$="'+field+'"]', $billingForm);
          var $dstSelect2 = $('select.has-select2[name$="'+field+'"]', $targetForm);

          $dstSelect2.select2('val', $srcSelect2.select2('val'));

        });
      });
    }


    function enableDisableAddressFields(ev) {
      var $form = $(ev.target).parents('.enrollee-form');
      var isChecked = $(ev.target).is(':checked');
      $.each(ADDRESS_FIELDS, function(i, field) {
          $('select[name$="'+field+'"], input[name$="'+field+'"]', $form)
            .not('.has-select2')
            .prop('readonly', isChecked)
            .end()
            .filter('.has-select2')
            .select2('readonly', isChecked);

      });
    }

    $('.enrollee-form div[id$="same_address_as_billing"]')
        .addClass('multi-student-only')
        .first()
        .css('visibility', 'hidden')
        .end()
        .find('input[type="checkbox"]')
        .on('change', copyAddressFromBilling)
        .on('change', enableDisableAddressFields)
        .trigger('change');

    $.each(ADDRESS_FIELDS, function(i, field) {
      var $field = $('[name$="'+field+'"]', $billingForm);
      $field.on('keypress change', function() {
        setTimeout(copyAddressFromBilling, 70);
      });
    });


  });

})(jQuery);
